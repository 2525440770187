<template>
  <div>
    <div class="vx-row match-height">
      <!-- <div class="vx-col w-full">
        <vx-card>
          <div class="vx-row items-end">
            <div class="vx-col">
              <label for="dateDebut" class="text-sm">Date de début</label>
              <flat-pickr v-model.lazy="dateDebut" :config="configdateTimePicker" class="w-full" name="dateDebut" />
            </div>
            <div class="vx-col">
              <label for="dateFin" class="text-sm">Date de fin</label>
              <flat-pickr v-model.lazy="dateFin" :config="configdateTimePicker" class="w-full" name="dateFin" />
            </div>
            <div class="vx-col">
              <vs-select v-if="principale" label="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="idOfficine">
                <vs-select-item  class="w-full" value="Tout" text="Tout" />
                <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
              </vs-select>
            </div>
            <div class="vx-col">
              <vs-button ref="loadableButton" id="button-with-loading" class="w-full vs-con-loading__container" color="primary" icon-pack="feather" icon="icon-search">Valider</vs-button>
            </div>
          </div>
        </vx-card>
      </div> -->
      <div class="vx-col w-full mt-5">
        <vx-card title="Statistiques client">
          <div class="mt-5">
            <echart-client></echart-client>
          </div>
        </vx-card>
      </div>
      <div class="vx-col w-full mt-2">
        <vx-card title="Stock" class="mb-base">
          <div class="mt-5">
            <echart-stock></echart-stock>
          </div>
        </vx-card>
      </div>
      <!-- <div class="vx-col w-full mt-2">
        <vx-card title="Commandes" class="mb-base">
          <div class="mt-5">
            <echart-commande></echart-commande>
          </div>
        </vx-card>
      </div> -->
    </div>
  </div>
</template>
<script>
const flatPickr = () => import('vue-flatpickr-component')
import 'flatpickr/dist/flatpickr.css'
import {French as FrenchLocale} from 'flatpickr/dist/l10n/fr.js'
const echartClient = () => import('./echart_client.vue')
const echartCommande = () => import('./echart_commande.vue')
const echartStock = () => import('./echart_stock.vue')
export default {
  components: {
    flatPickr,
    echartClient,
    echartCommande,
    echartStock
  },
  data () {
    return {
      activeUserInfos: null,
      idOfficine: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null,
      configdateTimePicker: { locale: FrenchLocale, dateFormat: 'd F Y' },
      dateDebut: null,
      dateFin: null
    }
  },
  computed: {
    officines () {
      return this.$store.state.officine.officines
    },
    principale () {
      return this.$store.getters['officine/officineIsPrincipale'](JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null)
    }
  },
  methods: {
    moneyFormatter (montant) {
    // Utilisation de la fonction locale pour formater le nombre en monnaie
      const formattedMontant = montant.toLocaleString('fr-FR', { minimumFractionDigits: 0 })

      // Remplacement de la virgule par l'espace comme séparateur de milliers
      return formattedMontant.replace('.', ' ')
    },
    officineName (id) {
      const officine = this.$store.state.officine.officines.find((o) => o._id === id)
      return officine.NomOffic
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((error) => {
          console.log(error)
        })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  created () {
    this.getOfficines()
  }
}
</script>
